.contactsArea {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 45%;
    right: 0;
}

.arrow {
    background-color: #103020;
    opacity: 0.9;
    color: #eee;
    height: 50px;
    width: 40px;
    font-size: 24px;
    border-radius: 10px 0 0 10px;
    border: none;
    cursor: pointer;
}

.arrow:hover {
    border: 3px #30b060 solid;
}

.contacts {
    display: grid;
    background-color: #103020;
    opacity: 0.9;
    width: 48px;
    border-radius: 10px 0 0 10px;
    -webkit-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}

.contact_link {
    margin: 6px;
    opacity: 0.9;
}

.contact_link:hover {
    opacity: 1;
}

.contacts.closed {
    width: 0px;
}

@media only screen and (max-width: 960px) {
    .contactsArea {
        display: none;
    }
}