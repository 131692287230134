.projects {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: #103020;
}

.projectsList {
    margin: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.projectLink {
    display: inline-grid;
    color: #eee;
    height: 100px;
    border: 1px solid #eee;
    align-items: center;
    text-align: center;
}

.projectLink:hover {
    border: 1px solid #30b060
}

.githubLink {
    color: #eee;
    margin-top: 20px;
}