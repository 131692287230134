.App {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/bg_santa.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}

header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #104020;
}

footer {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  color: #eee;
}

h1 {
  font-family: monospace;
}

a{
  color: #104020;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

a:hover {
  color: #30b060;
}

.copyright {
  font-size: 8px;
  position: absolute;
  bottom: 0px;
  opacity: 0.9;
}

.links1 {
  position: fixed;
  top: 10px;
  right: 10px;
}

.links2 {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 40vw;
  height: 40px;
  margin-top: 40px;
}

.link {
  opacity: 0.8;
}

.link:hover {
  opacity: 1;
}

.link img:last-child {
  display: none;
}

.link:hover img:last-child {
  display: block;
}

.link:hover img:first-child{
  display: none;
}

@media only screen and (max-width: 960px) {
  .links2 {
    width: 60vw;
  }
}