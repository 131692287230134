.side_bar {
    height: 100vh;
    max-width: 25%;
    top: 0;
    position: sticky;
    background-image: url('../../public/bg_sumela.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

.profile {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
}

.profile img {
    border: 1px solid #eee;
    border-radius: 50%;
}

.mail {
    width: 100%;
    color: #eee;
    position: absolute;
    font-size: 12px;
    bottom: 0;
    text-align: center;
}

.tabs {
    width: 100%;
}

.sideTab {
    min-height: 40px;
    width: 90%;
    margin-left: 10%;
    margin-top: 10px;
    border: none;
    font-size: 20px;
    background-color: transparent;
    cursor: pointer;
    color: #eee;
}

.sideTab.selected,
.sideTab:hover {
    background-color: #eee;
    color: #104020;
    border-radius: 20px 0 0 20px;
}


@media only screen and (max-width: 960px) {
    .side_bar {
        max-width: 33%;
    }
}