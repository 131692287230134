.portfolio_section {
    height: 100vh;
    margin: 0 30px;
    background-color: #eee;
    display: flex;
}

.title {
    cursor: pointer;
    display: flex;
}

.titleText {
    font-size: 24px;
    margin-right: 10px;
    color: #104020;
    margin-bottom: 0;
    font-weight: bold;
}

.jobDetail {
    font-size: 16px;
    color: #30b060;
    margin-bottom: 6px;
    align-self: flex-end;
}

.description {
    font-size: 20px;
    color: #104020;
    margin-bottom: 6px;
}

.description.hide {
    display: none;
}

.description.show {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}